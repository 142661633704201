.logo {
    height:inherit;
    width:inherit;
    color:#eee;
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon {
    width:24px;
    height:24px;
}