.tooltip > div {
    background-color:#111;
}

.tooltip > div > span {
    color:#111;
}

.iconLink {
    height:inherit;
    width:inherit;
    color:#a9a9a9;
    transition: all .3s ease 0s;
    display: flex;
    align-items: center;
    justify-content: center;
}

.iconLink:hover {
    color:#eee;
}

.activeIconLink {
    height:inherit;
    width:inherit;
    color:#00ADB5;
    display: flex;
    align-items: center;
    justify-content: center;
}